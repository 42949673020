<template>
  <div>
    <h2>Declaratie indienen</h2>

    <FlashMessage :message="message"/>

    <form ref="form" @submit.prevent="saveDeclaration">
      <p>
        <label>
          Titel<br>
          <input type="text" v-model="title" required />
        </label>
      </p>

      <p>
        <label>
          Omschrijving<br>
          <textarea v-model="description" required></textarea>
        </label>
      </p>

      <p>
        <label>
          Bedrag<br>
          <input v-model.number="amount" type="number" step="any" required />
        </label>
      </p>

      <button>Dien in bij penningmeester</button>
    </form>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
} from 'vue';
import { useMutation } from 'villus';
import FlashMessage from '../components/FlashMessage.vue';

export default defineComponent({
  name: 'Declaration',

  components: { FlashMessage },

  setup() {
    const message = ref('');

    const variables = reactive({
      amount: null,
      title: null,
      description: null,
    });

    const DeclarationQuery = `
        mutation createDeclaration($amount: Float!,
                          $title: String!,
                          $description: String!) {
        createDeclaration(
                   amount: $amount,
                   title: $title,
                   description: $description,)  {
          title,
          description,
          amount,
        }
      }
    `;

    const { execute } = useMutation(DeclarationQuery);

    const saveDeclaration = () => {
      execute(variables)
        .then((response) => {
          if (response.error) {
            message.value = `Er ging iets mis: ${response.error.message}`;
          } else {
            variables.amount = null;
            variables.title = null;
            variables.description = null;
            message.value = 'Ingediend';
          }
        });
    };

    return {
      ...toRefs(variables),
      saveDeclaration,
      message,
    };
  },
});
</script>
